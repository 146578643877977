$(document).ready(function () {


    // --------------  Equipment components Index page ---------------
    var equipment_components_index_page = document.querySelector('#equipment_components_grid');

    if (equipment_components_index_page != undefined) {

        var equipment_components_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name: 'Название',
                equipment_manufacturer: "Производитель",
                equipment_model: 'Оборудование',
                component_type_name: 'Тип',
                daterange: 'Даты производства',
                operating_regimes_name: 'Режим эксплуатации',
                drive_type_name: 'Тип привода',
                component_code: 'Код компоненты',
                change_intervals_s: 'Интервал смены',
                capacities: 'Заливка',
                is_active: 'Активен',
                change_intervals_count: '# интервалов замены',
                category_group: 'Группа категорий',
                category: 'Категория',
                lubricant_codes: 'Индустриальный код',
                oil_viscosities: 'Вязкости масел',
                fuel_type_name: "Тип топлива"
            },
            en: {
                created_at: 'Created at',
                name: 'Name',
                equipment_manufacturer: "Manufacturer",
                equipment_model: 'Equipment model',
                component_type_name: 'Type',
                daterange: 'Make date range',
                operating_regimes_name: 'Operating regime',
                drive_type_name: 'Drive type',
                component_code: 'Component code',
                change_intervals_s: 'Change interval',
                capacities: 'Capacities',
                is_active: 'Is active',
                change_intervals_count: '# interval count',
                category_group: 'Category group',
                category: 'category',
                lubricant_codes: 'Industry reference',
                oil_viscosities: 'Oil viscosities',
                fuel_type_name: "Fuel type",
            }
        };

        function getEquipmentComponentsColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return equipment_components_dictionary[culture][word]
        }

        function resize_window_of_operating_equipment_components() {
            $("#equipment_components_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_operating_equipment_components();
        });

        function equipment_componentRowColoringOnDatabound() {
            var grid = $("#equipment_components_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == 1) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#c5ebc7"); //green
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#eca6b0"); //red deactivated
                }
            });
        }

        $("#equipment_components_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/equipment_components.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name: {type: "string"},
                            equipment_manufacturer: {type: "string"},
                            equipment_model: {type: "string"},
                            component_type_name_en: {type: "string"},
                            operating_regimes_name_en: {type: "string"},
                            drive_type_name: {type: "string"},
                            component_code: {type: "string"},
                            capacities: {type: "string"},
                            is_active: {type: "boolean"},
                            change_intervals_count: {type: "string"},
                            category_group: {type: "string"},
                            category: {type: "string"},
                            lubricant_codes: {type: "string"},
                            oil_viscosities: {type: "string"},
                            fuel_type_name: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_operating_equipment_components();
                equipment_componentRowColoringOnDatabound();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/equipment_components/#=id#/">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/equipment_components/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                // {
                //     title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                //     field: "created_at",
                //     // filterable: {
                //     //     cell: {
                //     //         showOperators: false
                //     //     }
                //     // },
                //     format: "{0:dd.MM.yyyy | HH:mm:ss}",
                //     type: "date",
                //     filterable: false,
                //     width: 140
                // },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'category_group'),  //"Название отчета",
                    field: "category_group",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'category'),  //"Название отчета",
                    field: "category",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },

                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'equipment_manufacturer'),
                    field: "equipment_manufacturer",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'equipment_model'),  //"Название отчета",
                    field: "equipment_model",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'name'),  //"Название отчета",
                    field: "name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'component_type_name'),  //"Название отчета",
                    field: "component_type_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'capacities'),  //"Название отчета",
                    field: "capacities",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false
                },

                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'change_intervals_count'),  //"Название отчета",
                    field: "change_intervals_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },

                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'fuel_type_name'),  //"Название отчета",
                    field: "fuel_type_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },

                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'operating_regimes_name'),  //"Название отчета",
                    field: "operating_regimes_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'drive_type_name'),  //"Название отчета",
                    field: "drive_type_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'component_code'),  //"Название отчета",
                    field: "component_code",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'lubricant_codes'),
                    field: "lubricant_codes",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'oil_viscosities'),
                    field: "oil_viscosities",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'is_active'),  //"Год окончания выпуска",
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_active) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                }

            ]
        });

    }

});